<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'home-agentscout' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Home
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
        
    <div class="text-center my-3" v-if="Caricato == false">
      <b-spinner  v-if="errore_caricamento == false"
          variant="primary"
          style="width: 3rem; height: 3rem;"
          label="loading"
      />
      <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
    </div>

    <b-card bg-variant="Default" v-if="Caricato">
      <b-row class="match-height">
        <b-col
          md="11"
          cols="10" 
          class="mb-2"
        >
          <!-- input search -->
          <b-input-group>
            <b-input-group-prepend>
              <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca.sezione" :options="options_ricerca" disabled-field="notEnabled"></b-form-select>
            </b-input-group-prepend>
              <b-form-input v-model="campiRicerca.nome"
                  placeholder="ricerca generale"
                  type="text" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click.prevent="ricercaGenerale">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>

          </b-input-group>
        </b-col>
        <b-col
          md="1"
          cols="2"
          class="mb-2"
        >
          <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset" block v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
        </b-col>
        
      </b-row>

        <!-- table -->
        <vue-good-table
        mode="remote"
        ref="table"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :rows="rows"
        :columns="columns"
        :isLoading=isLoading
        :search-options="{ 
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        
        <template slot="loadingContent">
          <div class="text-center my-3">
              <b-spinner
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
              />
              <div class="d-block pt-1 text-primary">Caricamento</div>
          </div>
        </template>
        
        <div slot="emptystate"> <div class="text-center py-4"> Nessun dato disponibile - (Messaggio personalizzabile) </div> </div>

        <template
            slot="table-row"
            slot-scope="props"
        >

            <!-- Column: importo_lordo -->
            <span v-if="props.column.field === 'importo_lordo'">
              {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
            </span>

            <!-- Column: id_stato -->
            <span v-else-if="props.column.field === 'id_stato'">
              {{ statusFattura(props.formattedRow[props.column.field]) }}
            </span>

            <!-- Column: bonifico -->
            <span v-else-if="props.column.field === 'bonifico'">
              {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
            </span>
            
            <!-- Column: incassato -->
            <span v-else-if="props.column.field === 'incassato'">
                <b-badge :variant="statusVariant(props.row.incassato)">
                {{ props.row.incassato }}
                </b-badge>
            </span>

            <!-- Column: updated_at -->
            <div v-else-if="props.column.field === 'updated_at'">
              {{ formattazioneDataOra(props.row.updated_at, 'it') }}
            </div>

            <!-- Column: azioni -->
            <span v-else-if="props.column.field === 'azioni'">
              <div class="text-nowrap">
                <feather-icon
                  icon="ArrowRightIcon"
                  class="cursor-pointer text-primary"
                  size="21"
                  v-b-tooltip.hover.v-primary
                  title="Azioni"
                  :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                  @click="sidebarAction(props.row.id, props.row.status.name, props.row.license_data, props.row.fic_numero, props.row.fic_data, props.row.fic_url_pdf, props.row.fic_invio_email_copia_cortesia, props.row.customer.billing.company_name)"
                />
              </div>
            </span>

            <!-- Column: standard -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Visualizzazione da 1 a 
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['10','25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> di {{ props.total }} righe </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
            
    </b-card>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar-right"
      bg-variant="white"
      :title="infoSideBar.titolo"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          
          <div class="divider">
              <div class="divider-text text-primary fw-bolder">DETTAGLIO</div>
          </div>
          <b-row cols="1">
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Licenza:</span> {{ infoSideBar.license_data.ref_code }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Tipo:</span> {{ infoSideBar.license_data.type }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">prezzo:</span> {{ infoSideBar.license_data.price }} €
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-0">PAGAMENTO</div>
          </div>
          <b-row cols="1">
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Stripe payment_intent:</span> license_payment_log.api_payment_intent
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Stripe invoice:</span> license_payment_log.api_payment_intent.api_invoice
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-0">FATTURA ELETTRONICA</div>
          </div>
          <b-row cols="1">
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Numero:</span> {{ infoSideBar.fic_numero }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Data di emissione:</span> {{ infoSideBar.fic_data }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Stato:</span> {{ infoSideBar.status_name }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.fic_invio_email_copia_cortesia === true">
              <span class="font-weight-bold">Copia cortesia:</span> inviata al cliente
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="$router.push({ name: 'crm-fatture-show', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="EyeIcon" /> Visualizza scheda
          </b-button>
          <b-button
            v-if="infoSideBar.fic_url_pdf !== null"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            target="_blank"
            :href="infoSideBar.fic_url_pdf"
          >
            <feather-icon icon="FileTextIcon" /> Visualizza Fattura Elettronica
          </b-button>
        </div>

      </div>
    </b-sidebar>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { prezzoFormattato } from '@core/utils/funzioniGlobali' 

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton,
  },
  setup() {
      return {
          prezzoFormattato,
      }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true, 
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Licenza',
          field: 'license_data.ref_code',
          width: '120px',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Tipo',
          field: 'license_data.type',
          width: '120px',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Cliente',
          field: 'customer.billing.company_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Numero',
          field: 'fic_numero',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Data',
          field: 'fic_data',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Importo',
          field: 'importo_lordo',
          width: '100px',
          tdClass: 'text-right',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Stato',
          field: 'id_stato',
          width: '140px',
          html: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
            filterDropdownItems: [
                { value: 10, text: 'Creata' },  
                { value: 20, text: 'Sincronizzata' },  
                { value: 30, text: 'Copia cortezia inviata' },
                { value: 40, text: 'SDI inviata' },
            ],
          },
        },
        {
          label: 'Incassato',
          field: 'incassato',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Bonifico',
          field: 'bonifico',
          width: '100px',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Data Bonifico',
          field: 'data_bonifico',
          width: '120px',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: 'Filtra',
          },
        },

        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      resetState: 'hidden',
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams: {
        columnFilters: {},
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca: [
        { value: null, text: 'seleziona...' },
        { value: 'customer.billing.company_name', text: 'Ragione Sociale' },
        { value: 'fic_numero', text: 'Numero Fattura' },
      ],
      campiRicerca: {
        nome: '',
        sezione: null,
      },
      infoSideBar: {
        id: '',
        status_name: '',
        license_data: [ ],
        fic_numero: '',
        fic_data: '',
        fic_url_pdf: null,
        fic_invio_email_copia_cortesia: false,
        titolo: '',
      },
      Caricato: false,
      errore_caricamento: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'Pagata'    : 'light-success',
        'Da Pagare' : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    statusFattura() {
      const statusInvoice = {
        /* eslint-disable key-spacing */
        10  : 'Creata',
        20  : 'Sincronizzata',
        30  : 'Copia cortezia inviata',
        40  : 'SDI inviata',
        /* eslint-enable key-spacing */
      }

      return status => statusInvoice[status]
    },
  },
  mounted() {
    //in caso di resize del browser -> ricalcolo della larghezza della finestra
    window.onresize = () => {
      this.checkWindowsSizeAndResponsiveGoodTable();
    }
  },
  created() {
    this.checkWindowsSizeAndResponsiveGoodTable();
    
    //adesso faccio il caricamento dei dati
    this.loadItems();

    this.Caricato = true;
    this.errore_caricamento = false;
  },
  methods: {
    checkWindowsSizeAndResponsiveGoodTable(){
      if(window.innerWidth <1000){
        this.$set(this.columns[3], 'hidden', true)
        this.$set(this.columns[4], 'hidden', true)
        this.$set(this.columns[5], 'hidden', true)
      } else if(window.innerWidth <1200){
        this.$set(this.columns[3], 'hidden', false)
        this.$set(this.columns[4], 'hidden', false)
        this.$set(this.columns[5], 'hidden', true)
      } else if(window.innerWidth <1400){
        this.$set(this.columns[3], 'hidden', false)
        this.$set(this.columns[4], 'hidden', false)
        this.$set(this.columns[5], 'hidden', false)
      } else {
        //tutte le colonne visualizzate
        this.$set(this.columns[3], 'hidden', false)
        this.$set(this.columns[4], 'hidden', false)
        this.$set(this.columns[5], 'hidden', false)
      }
    },
    formattazioneDataOra(converted_at,lang) {
      if (converted_at) {
        return moment(String(converted_at)).locale(lang).format('DD/MM/YY - HH:II')
      } else {
        return '---'
      }
    },
    ricercaGenerale(){
      //console.log("ricerca generale - chiamata Api per avere la ricerca su tutti i dati e NON solo sui dati dell'attuale viewport della tabella ;-)")
      console.log(this.campiRicerca)

      if((this.campiRicerca.sezione !== null) && (this.campiRicerca.sezione !== 'undefined')){
        console.log("ok ricerca valida")

        //aggiorna parametri con la stessa funzione del filtro colonna ;-)
        this.updateParams({
          columnFilters: {
            [this.campiRicerca.sezione]: this.campiRicerca.nome
          },
        });

        //forza reset filtro colonne
        this.$refs.table.reset();

        this.loadItems();

        //attiva bottone "reset"
        this.resetState = 'attiva';
      }
    },
    clickReset(){
      console.log("clickReset");

      //forza reset filtro colonne
      this.$refs.table.reset();

      //forza reset parametri iniziali della tabella
      this.updateParams(this.startServerParams);
      this.loadItems();

      //reset campi ricerca
      this.campiRicerca.nome = '';
      this.campiRicerca.sezione = null;
      //disattiva bottone "reset"
      this.resetState = 'hidden';
    },
    advanceFilter(val) {
      //this.searchTerm = val

      this.updateParams(val);
      this.loadItems();
    },
    sidebarAction(id, status_name, license_data, fic_numero, fic_data, fic_url_pdf, fic_invio_email_copia_cortesia, titolo) {
      //console.log(license_data);
      
      this.infoSideBar = { id: id,
        status_name: status_name,
        license_data: license_data,
        fic_numero: fic_numero,
        fic_data: fic_data,
        fic_url_pdf: fic_url_pdf, 
        fic_invio_email_copia_cortesia: fic_invio_email_copia_cortesia,
        titolo: titolo, };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    
    updateParams(newProps) {
      //console.log(newProps)

      if (typeof newProps.columnFilters !== 'undefined'){
        if(typeof newProps.columnFilters.aggregato_name_surname !== 'undefined'){
          //in caso di campo con "stringa" => vai di filtro per il valore richiesto
          if(newProps.columnFilters.aggregato_name_surname != ''){
            newProps.columnFilters['user.surname'] = newProps.columnFilters.aggregato_name_surname;
          }

          //in caso di campo in cui avevo impostato un filtro e lo voglio cancellare
          if(newProps.columnFilters.aggregato_name_surname == ''){
            delete(newProps.columnFilters['user.surname']);
          }
        }

      }

      //attiva bottone "reset" filtro o ricerca
      this.resetState = 'attiva';

      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      //console.log('cambio ordinamento')
      //console.log(params)

      let field_ricerca = '';

      //colonne con relazione
      if(params[0].field == 'source.text'){
        field_ricerca = 'source.name';
      } else if(params[0].field == 'aggregato_name_surname'){
        field_ricerca = 'user.surname';
      } else {
        field_ricerca = params[0].field;
      }

      this.updateParams({
        sort: [{
          type: params[0].type,
          field: field_ricerca,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      //console.log("AGGIORNAMENTO FILTRO COLONNA --------")
      this.updateParams(params);
      this.loadItems();
    },

    loadItems() {
      this.isLoading = true;

      this.$http.get('api/crm/invoice/list', {
        params: this.serverParams 
        }).then(response => { 
            if(response.data.statusCode == 200){
              //ok
              this.totalRecords = response.data.reply.meta.total;
              this.rows = response.data.reply.data;
              
            } else if(response.data.statusCode == 422){
              //errori con testo di notifica ok dall'api
              this.$swal({
                title: 'Errore',
                text: 'codice errore: '+response.data.reply,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            } else {
              //tutto gli altri possibili errori
              this.$swal({
                title: 'Errore Generico',
                text: 'codice errore: '+response.data.statusCode,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            }
            
            //rimuovi il loading dopo il caricamento dei dati nella tabella
            this.isLoading = false;
        
        })

    },
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.riga_sidebar{
  padding-bottom: 5px;
}
</style>